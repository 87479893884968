import { performRequest } from './api-handler'
import { URLS } from '../utility/urls'

export default () => {
    const { GET_BUILDINGS_DETAILS, ADD_BUILDINGS_DETAILS, UPDATE_BUILDINGS_DETAILS, DELETE_BUILDINGS_DETAILS, UPDATE_Building_PRIORITY } = URLS
    return {
        getBuildingsDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_BUILDINGS_DETAILS, body),
        addBuildingsDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_BUILDINGS_DETAILS, body),
        updateBuildingsDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_BUILDINGS_DETAILS, body),
        updateBuildingPriority: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_Building_PRIORITY,body),
        deleteBuildingsDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_BUILDINGS_DETAILS, body)
    }
}